import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EncryptDataService } from '../security/encypt-data.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root',
})
export class Interceptor implements HttpInterceptor {
	private token: string;

	constructor(private router: Router, private securityLocalStorage: EncryptDataService, private dialog: MatDialog) { }

	setToken(token: string) {
		this.token = token;
	}

	getToken() {
		const storedData = localStorage.getItem('data');
		if (storedData) {
			const data = JSON.parse(this.securityLocalStorage.decryptData(storedData));
			return data.token || null;
		}
		return null;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const token = this.getToken();
		if (token) {
			headers = headers.set('token', token);
		}

		const reqClone = req.clone({ headers });
		return next.handle(reqClone).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('Error del cliente:', error.error.message);
		} else {
			this.dialog.closeAll();  // Cierra todos los diálogos en caso de error
			console.error(`Código de error ${error.status}, mensaje: ${error.message}`);

			// Manejo de errores 401 y 403
			if (error.status === 401 || error.status === 403) {
				console.log(error.status);
				localStorage.removeItem('data'); // Limpiar el token expirado
				if (!localStorage.getItem('tokenExpiredToastShown')) {
					localStorage.setItem('tokenExpiredToastShown', 'true');
				}
				this.router.navigate(['/'+error.status]);
			}
			// Manejo de error 500 para caso específico
			else if (error.status === 500 && error.error && error.error.message && error.error.message.includes('Clave primaria no válida')) {
				console.error('Error: Clave primaria incorrecta');
			}
		}

		return throwError('Ocurrió un error. Por favor, inténtelo de nuevo más tarde.');
	}
}
