import { Routes } from '@angular/router';
import { Page404Component } from './shared/pages/page404/page404.component';
import { Page403Component } from './shared/pages/page403/page403.component';
import { Page500Component } from './shared/pages/page500/page500.component';
import { Page503Component } from './shared/pages/page503/page503.component';
import { HouseAccountGuard } from './core/guards/house-account.guard';
import { AdminGuard } from './core/guards/admin.guard';

export const routes: Routes = [
    {
        path:'auth',
		loadComponent: () =>
			import('../app/modules/auth/auth.component'),
        children: [
            {
                path:'login',
                title:'Login',
                data:{translate:'menu-auth.login'},
                loadComponent: () => import('./modules/auth/pages/login/login.component')
            },
			{
                path:'forgot-password',
                title:'FORGOT',
                data:{translate:'menu-auth.login'},
                loadComponent: () => import('./modules/auth/pages/forgot-password/forgot-password.component')
            },
            { path:'', redirectTo:'login', pathMatch:'full' }
        ]
    },
	{
		path:'dashboard',
		loadComponent: () => import('./modules/dashboard/dashboard.component'),
		children:[
			{
				path:'clients',
				title:'CLIENTS',
				data:{translate:'clients'},
				loadComponent: () => import('./modules/dashboard/pages/clients/clients.component')
			},
			{
				path:'locker',
				title:'LOCKERS',
				data:{translate:'lockers'},
				loadComponent: () => import('./modules/dashboard/pages/locker/locker.component')
			},
			{
				path:'locker-detail/:id_locker',
				title:'LOCKERS DETAIL',
				data:{translate:'lockersDetails'},
				loadComponent: () => import('./modules/dashboard/pages/locker-detail/locker-detail.component')
			},
			{
				path:'order-detail/:id',
				title:'Orders DETAIL',
				data:{translate:'ordersDetails'},
				loadComponent: () => import('./modules/dashboard/pages/orders-detail/orders-detail.component')
			},
			{
				path:'users',
				title:'Users',
				data:{translate:'users'},
				loadComponent: () => import('./modules/dashboard/pages/users/users.component')
			},
			{
				path:'profile',
				title:'Profile',
				data:{translate:'profile'},
				loadComponent: () => import('./modules/dashboard/pages/profile/profile.component')
			},
			{
				path:'version',
				title:'Version',
				data:{translate:'version'},
				loadComponent: () => import('./modules/dashboard/pages/version/version.component')
			},
			{
				path:'company',
				title:'Compañia',
				data:{translate:'company'},
				loadComponent: () => import('./modules/dashboard/pages/company/company.component'),
				canActivate: [AdminGuard] // Solo los administradores pueden acceder
			},
			{
				path:'orders',
				title:'Orders',
				data:{translate:'orders'},
				loadComponent: () => import('./modules/dashboard/pages/orders/orders.component')
			},
			{
				path:'add_company',
				title:'Compañia',
				data:{translate:'company'},
				loadComponent: () => import('./modules/dashboard/pages/add-company/add-company.component'),
				canActivate: [AdminGuard] // Solo los administradores pueden acceder
			}
		],
		canActivate: [HouseAccountGuard]
	},
	{
		path:'403',
		component:Page403Component
	},
    {
        path:'404',
        component:Page404Component
    },
	{
        path:'500',
        component:Page500Component
    },
	{
        path:'503',
        component:Page503Component
    },
    { path:'', redirectTo:'/auth', pathMatch:'full' },
    { path:'**', redirectTo:'/404', pathMatch:'full' }
];
