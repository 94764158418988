import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from './core/services/theme.service';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './core/interceptors/interceptor.service';
import { TranslateService } from './core/services/translate/translate.service';
import { LocaleService } from './core/services/translate/locale.service';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet,MatDialogModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: (localeService: LocaleService) => localeService.getLocale(),
            deps: [LocaleService],
        },
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private themeService: ThemeService,
        private translateService: TranslateService,
        private localeService: LocaleService
    ) {}

	ngOnInit(): void {
		this.themeService.initializeTheme(); // Aplica un tema inicial si es necesario
	}

	changeLanguage(language: string): void {
		const locales = { es: 'es-ES', en: 'en-US' };
		const locale = locales[language] || 'en-US';

		try {
			this.translateService.changeLang(); // Cambia el idioma en TranslateService
			this.localeService.changeLocale(locale);    // Cambia el locale
			// Aplica cambios adicionales si es necesario, como actualizar textos dinámicos
		} catch (error) {
			console.error('Error al cambiar idioma:', error);
		}
	}
}
