// * Angular
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter, withViewTransitions } from '@angular/router';
// * Routes
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { MatDialogModule } from '@angular/material/dialog';
import { Interceptor } from './core/interceptors/interceptor.service';

export const appConfig: ApplicationConfig = {
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Interceptor,
			multi: true,
		},
		// Configuración de rutas
		provideRouter(
			routes,
			withViewTransitions(), // Habilita transiciones entre vistas
		),
		// HttpClient con interceptores
		provideHttpClient(withInterceptorsFromDi()),
		// Animaciones asíncronas
		provideAnimationsAsync(),
		// Charts con configuraciones predeterminadas
		provideCharts(withDefaultRegisterables()),
	]
};
