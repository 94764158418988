<div class="container-error-403">
	<div class="box-container">
		<div class="box-header-container">
			<div class="box-header-title">
				<div class="box-header">
					<div class="text">
						Error 403
					</div>
					<div class="subtitle">
						Acceso restringido
					</div>
				</div>
				<div class="box-footer">
					Parece que no tienes permisos para ver esta pagina. Intenta iniciar sesión nuevamente, si este error sigue ocurriendo no dudes en contactarnos. Te ayudaremos a solventarlo.
				</div>
			</div>
			<app-button label="Salir e intentar iniciar sesion" buttonClass="btn-md-primary-full" (click)="tryToLogin()">
			</app-button>
		</div>
		<div class="box-footer">
		</div>
	</div>
</div>
