import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HouseAccountGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.authService.getUserData(); // Obtener usuario autenticado

    if (!user) {
      this.router.navigate(['/auth/login']); // Redirigir si no está autenticado
      return false;
    }

    if (user.data_company.id_user_type === 2) {
      return true; // Permitir acceso a administradores
    }

    if (user.data_company.id_user_type === 6) {
      if (state.url.includes('/dashboard/company')) {
        this.router.navigate(['/403']); // Bloquear acceso a 'company' para cuentas de casa
        return false;
      }
      return true; // Permitir acceso a otras páginas
    }

    this.router.navigate(['/403']); // Si no es admin ni house account, redirigir a 403
    return false;
  }
}
