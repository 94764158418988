import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LocaleService {
	private readonly supportedLocales = ['es-ES', 'en-US']; // Locales soportados
	private readonly defaultLocale = 'es-ES'; // Locale por defecto
	private localeSubject = new BehaviorSubject<string>(
		localStorage.getItem('appLocale') || 'es-ES'
	);

	locale$ = this.localeSubject.asObservable();

	constructor() {
		const savedLocale = localStorage.getItem('appLocale');
		const initialLocale = savedLocale && this.isValidLocale(savedLocale) ? savedLocale : this.defaultLocale;
		this.localeSubject = new BehaviorSubject<string>(initialLocale);
	}

	changeLocale(locale: string): void {
		if (!this.isValidLocale(locale)) {
			console.warn(`Locale no soportado: ${locale}`);
			return;
		}
		this.localeSubject.next(locale);
		localStorage.setItem('appLocale', locale);
	}

	get currentLocale(): string {
		return this.localeSubject.getValue();
	}

	private isValidLocale(locale: string): boolean {
		return this.supportedLocales.includes(locale);
	}

	 // Método explícito para obtener el locale actual
	getLocale(): string {
        return this.currentLocale; // Usa el getter para obtener el valor actual
    }
}
